import React from "react";

export const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "red",
        color: "white",
        textAlign: "center",
        padding: "1ch",
      }}
    >
      <span style={{ fontSize: "12px" }}>
        Copyright 2008 Zadez Technology Corporation. All Rights Reserved.
      </span>
    </div>
  );
};
