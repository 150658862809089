import { DesktopLanding } from "components/organisms/DesktopLanding/DesktopLanding";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { MobileLanding } from "components/organisms/MobileLanding/MobileLanding";
import { useTranslation } from "react-i18next";
import { Footer } from "components/organisms/Footer/Footer";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9e0b0b",
    },
    secondary: {
      main: "#BA1B1B",
    },
  },
});

const languages = [
  {
    value: "vi",
    name: "Vietnam",
    code: "VN",
  },
  {
    value: "en",
    name: "English",
    code: "US",
  },
];

function App() {
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {matches ? (
          <DesktopLanding t={t} lang={languages} />
        ) : (
          <MobileLanding t={t} lang={languages} />
        )}
        <Footer />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
