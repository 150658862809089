import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

const data = {
  backgroundImage: "images/working-code.webp",
  leftBgImage: "images/circuit-line.webp",
  contact: [
    {
      name: "zadez-hotline",
      src: "images/image001.webp",
      link: "tel:02877770053",
    },
    {
      name: "zadez-zadelo-official",
      src: "images/image002.webp",
      link: "zalo://qr/link/3126944695787017498",
    },
    {
      name: "zadez-facebook-messenger",
      src: "images/image003.webp",
      link: "https://m.facebook.com/msg/zadezvietnam/?show_interstitial=0&mdotme_uri=https%3A%2F%2Fm.me%2Fzadezvietnam&source_id=1441792&handler=m.me&referer&refsrc=deprecated&_rdr",
    },
  ],
  social: [
    {
      link: "https://www.facebook.com/ZadezVietnam/",
      icon: <FacebookIcon />,
    },
    {
      link: "https://www.youtube.com/c/ZADEZVIETNAM",
      icon: <YouTubeIcon />,
    },
  ],
};

export default data;
