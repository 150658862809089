import { LangSwitcher } from "../LangSwitcher/LangSwitcher";
import data from "data/data";
import { Button, Grid, Typography } from "@mui/material";
import { Animated } from "react-animated-css";

export const MobileLanding = ({ t, lang }) => {
  return (
    <section
      style={{
        backgroundImage: `url(${data.leftBgImage})`,
        backgroundSize: "percentage",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${data.backgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "30vh",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "0 10px 0",
        }}
      >
        <div>
          <div
            style={{
              marginBottom: "5vh",
              marginTop: "2.5vh",
              display: "flex",
              justifyContent: "flex-start",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <img src="zadez.svg" alt="zadez-logo" width="100vw" height="auto" />
            <Animated animationIn="bounceInLeft" animationOut="fadeOut">
              <LangSwitcher lang={lang} />
            </Animated>
          </div>
          <hr style={{ maxWidth: "50vw", margin: "0px" }} />
          <div>
            <Animated animationIn="tada" animationInDelay="100">
              <h4
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "24px",
                  textAlign: "left",
                }}
              >
                {t("title")}
              </h4>
            </Animated>
            <Animated animationIn="bounceInDown" animationInDelay="300">
              <span>{t("description.part1")}</span>
            </Animated>
          </div>
        </div>
        <br></br>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {data.contact.map((text, i) => (
            <Animated
              animationIn="bounceInUp"
              animationInDelay={i * 100}
              key={i}
            >
              <a
                key={i}
                href={text.link}
                target="_blank"
                referrerPolicy="no-referrer"
                rel="noreferrer"
              >
                <img src={text.src} alt={text.name} />
              </a>
            </Animated>
          ))}
        </div>
        <br></br>
        {/* Social */}
        <div>
          <Animated animationIn="lightSpeedIn" animationInDelay="500">
            <Typography
              textAlign="center"
              variant="h6"
              textTransform="uppercase"
              fontWeight="bold"
            >
              {t("social")}
            </Typography>
          </Animated>
          <br></br>
          <Grid
            container
            direction="row"
            justifyContent="center"
            columnSpacing={10}
          >
            {data.social.map((text, i) => (
              <Grid item key={i}>
                <Animated animationIn="zoomIn" animationInDelay={i * 500}>
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    href={text.link}
                  >
                    {text.icon}
                  </Button>
                </Animated>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <br />
    </section>
  );
};
