// import useTheme from "@mui/material/styles/useTheme";
import i18next from "i18next";

export const LangSwitcher = ({ lang }) => {
  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {lang.map((text, i) => (
        <div key={i} onClick={() => changeLanguage(text.value)}>
          <img
            alt={text.name}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${text.code}.svg`}
            width="30vw"
            height="auto"
          />
        </div>
      ))}
    </div>
  );
};
