import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-http-backend";
import commonEn from "./en/trans.json";
import commonVi from "./vi/trans.json";

const resources = {
  vi: { common: commonVi },
  en: { common: commonEn },
};
i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "vi"],
    fallbackLng: "en",
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources,
    ns: ["common"],
    defaultNS: "common",
    // Options for language detector
  });

export default i18next;
