import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LangSwitcher } from "../LangSwitcher/LangSwitcher";
import data from "data/data";
import { Animated } from "react-animated-css";

export const DesktopLanding = ({ t, lang }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Grid
      container
      style={{
        height: "100vh",
      }}
      direction={matches ? "row" : "column-reverse"}
      justifyContent="flex-end"
      alignItems="stretch"
    >
      <Grid
        sm={8}
        md={8}
        lg={6}
        xl={5}
        item
        style={{
          backgroundImage: `url(${data.leftBgImage})`,
          backgroundSize: "cover",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.3)",
          display: "table",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <div
          style={{
            verticalAlign: "middle",
            display: "table-cell",
          }}
        >
          <Animated animationIn="bounceInLeft" animationOut="fadeOut">
            <LangSwitcher lang={lang} />
          </Animated>
          <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid item>
              <div style={{ marginBottom: "5vh", marginTop: "2.5vh" }}>
                <img
                  src="zadez.svg"
                  alt="zadez-logo"
                  width="100vw"
                  height="auto"
                />
              </div>

              <Animated animationIn="tada" animationInDelay="100">
                <Typography
                  fontWeight="bold"
                  variant={matches ? "h3" : "h4"}
                  textTransform="uppercase"
                  textAlign="left"
                >
                  {t("title")}
                </Typography>
              </Animated>
              <Animated animationIn="bounceInDown" animationInDelay="300">
                <Typography variant="body1">
                  {t("description.part1")}
                </Typography>
              </Animated>
            </Grid>
          </Grid>
          <Box mt={2}></Box>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {data.contact.map((text, i) => (
              <Grid item key={i}>
                <Animated animationIn="bounceInUp" animationInDelay={i * 100}>
                  <a
                    href={text.link}
                    target="_blank"
                    referrerPolicy="no-referrer"
                    rel="noreferrer"
                  >
                    <img src={text.src} alt={text.name} />
                  </a>
                </Animated>
              </Grid>
            ))}
          </Grid>
          <Box mt={2}></Box>
          <Grid item>
            <Animated animationIn="lightSpeedIn" animationInDelay="500">
              <Typography
                textAlign="center"
                variant="h6"
                textTransform="uppercase"
                fontWeight="bold"
              >
                {t("social")}
              </Typography>
            </Animated>
            <Box mt={2}></Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              columnSpacing={10}
            >
              {data.social.map((text, i) => (
                <Grid item key={i}>
                  <Animated animationIn="zoomIn" animationInDelay={i * 500}>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      href={text.link}
                    >
                      {text.icon}
                    </Button>
                  </Animated>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid
        item
        sm={4}
        md={4}
        lg={6}
        xl={7}
        style={{
          backgroundImage: `url(${data.backgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "100%",
        }}
      ></Grid>
    </Grid>
  );
};
